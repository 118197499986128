import { CarePlanDTO, UpdateCarePlanDTO, updateCarePlan } from '@api/mainServiceAPI';
import { selectCurrentProfile } from '@sliceUser';
import { Button, Modal, message } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { COURSE_MAX_DAYS, CourseProceduresList, getOcclusionByDays } from 'src/COURSES_CONFIG';
import { CourseDevice } from 'src/enums/course-device.enum';
import {
    CourseStep,
    ICourseForm,
    IOcclusionDay,
    IProcedureFormItem,
    fixProcedureSettingsTypes,
} from 'src/pages/_shared/DoctorAppointmentPage/sessions/ConsultationSession/steps/CourseStep/CourseStep';

interface ICourseSettingsModalProps {
    carePlan?: CarePlanDTO;
    visible: boolean;
    editable?: boolean;

    onClose: () => void;
}

export const CourseSettingsModal = ({ carePlan, visible, editable = true, onClose }: ICourseSettingsModalProps) => {
    const currentProfile = useSelector(selectCurrentProfile);

    const [preparedData, setPreparedData] = useState<ICourseForm>();

    useEffect(() => {
        if (carePlan && Object.keys(carePlan).length) {
            const {
                status,
                extended,
                planDefinitionID,
                durationInDays,
                occlusionSettings,
                procedureSettings,
                needGlasses,
                needGlassesWithoutDyo,
                glassesWithoutDyoSize,
                deviceDefinitionIDs,
                targetEye,
                occlusionType,
                notesForNurse
            } = carePlan;

            setPreparedData({
                status,
                extended,
                planDefinitionID,
                durationInDays: durationInDays || COURSE_MAX_DAYS,
                needGlasses,
                targetEye,
                procedures: CourseProceduresList[planDefinitionID!].map((p) => {
                    const exist = procedureSettings?.find((x: IProcedureFormItem) => x.procedure === p);
                    if (exist) {
                        return {
                            ...exist,
                            selected: exist.selected === 'true',
                            ou: exist.ou === '{}' ? {} : fixProcedureSettingsTypes(exist.ou) || {},
                            od: exist.od === '{}' ? {} : fixProcedureSettingsTypes(exist.od) || {},
                            os: exist.os === '{}' ? {} : fixProcedureSettingsTypes(exist.os) || {},
                        };
                    } else {
                        return { selected: false, procedure: p };
                    }
                }),
                occlusionType: occlusionType as any,
                occlusionSettings: (occlusionSettings as IOcclusionDay[]).length
                    ? (occlusionSettings as IOcclusionDay[])
                    : Array.from({ length: COURSE_MAX_DAYS }, (_, i) => ({ day: i + 1 })),
                needGlassesWithoutDyo,
                glassesWithoutDyoSize,
                deviceDefinitionIDs: deviceDefinitionIDs as CourseDevice[],
                notesForNurse
            });
        }
    }, [carePlan]);

    const submitCourseSettings = (formValue: ICourseForm) => {
        if (formValue) {
            const {
                extended,
                planDefinitionID,
                durationInDays,
                needGlasses,
                occlusionType,
                procedures,
                targetEye,
                glassesWithoutDyoSize,
                needGlassesWithoutDyo,
                occlusionSettings,
                deviceDefinitionIDs,
                notesForNurse,
            } = formValue;

            const mappedOcclusionDays = occlusionType === 'manually' ? occlusionSettings : getOcclusionByDays(durationInDays || 0, occlusionType);

            const baseBody = {
                extended,
                needGlassesWithoutDyo,
                glassesWithoutDyoSize,
                targetEye,
                needGlasses,
                durationInDays,
                occlusionType,
                planDefinitionID,
                deviceDefinitionIDs,
                notesForNurse,

                occlusionSettings: mappedOcclusionDays,
                procedureSettings: procedures
                    ?.filter((x) => x.selected)
                    .map((x) => {
                        if (targetEye === 'ou') {
                            if (occlusionType === 'without') {
                                delete x.od;
                                delete x.os;
                            } else {
                                delete x.ou;
                            }
                        } else {
                            delete x.ou;
                        }

                        return x;
                    }),
            };

            const bodyUpdate: UpdateCarePlanDTO = {
                ...baseBody,
                notRecommended: false,
                practitionerModifierId: currentProfile?.fhirId,
            };

            updateCarePlan(carePlan!.fhirId!, bodyUpdate).then((res) => {
                message.success(t("head_physician_appointment.procedure_settings_changed"));
                onClose();
            });
        }
    };

    return (
        <>
            {visible && (
                <Modal
                    width="960px"
                    title={`${t('enums.planDefinition.' + preparedData?.planDefinitionID)} ${preparedData?.extended ? t("head_physician_appointment.extended") : ''}`}
                    open={visible}
                    onCancel={() => onClose()}
                    footer={[
                        <Button style={{ marginLeft: 'auto' }} key="close" type="primary" onClick={() => onClose()}>
                            {t('modals.buttons.close')}
                        </Button>,
                    ]}
                >
                    <CourseStep
                        onSubmit={(formValue) => submitCourseSettings(formValue)}
                        onFormChange={() => {}}
                        initialValues={preparedData}
                        modalMode={true}
                        disabled={!editable}
                    />
                </Modal>
            )}
        </>
    );
};
