export interface MediaStreamConstraints {
    audio: {
        deviceId?: { exact: string }
    } | boolean;
    video: {
        width: {max: number};
        frameRate: {
            max: number;
            min: number;
        };
        deviceId?: { exact: string };
    }
}

export const VIDEO_PARAMS_ECONOMY = {
    maxWidth: 480,
    maxFrameRate: 30,
    minFrameRate: 20,
};

export const VIDEO_PARAMS_FULL = {
    maxWidth: 720,
    maxFrameRate: 30,
    minFrameRate: 20,
};

export function formMediaStreamConstraints(economyMode: boolean, videoDeviceInfo: MediaDeviceInfo | undefined, audioInputDeviceInfo: MediaDeviceInfo | undefined): MediaStreamConstraints {
    const constraints: MediaStreamConstraints = {
        audio: audioInputDeviceInfo ? {
            deviceId: {exact: audioInputDeviceInfo.deviceId},
        } : true,
        video: {
            width: {max: economyMode ? VIDEO_PARAMS_ECONOMY.maxWidth : VIDEO_PARAMS_FULL.maxWidth},
            frameRate: {
                max: economyMode ? VIDEO_PARAMS_ECONOMY.maxFrameRate : VIDEO_PARAMS_FULL.maxFrameRate,
                min: economyMode ? VIDEO_PARAMS_ECONOMY.minFrameRate : VIDEO_PARAMS_FULL.minFrameRate
            }
        }
    };
    if (videoDeviceInfo) {
        constraints.video.deviceId = {exact: videoDeviceInfo.deviceId};
    }
    return constraints;
}

export const ScreenSharingStreamConstraints = {
    audio: false,
    video: {
        width: {ideal: 1366, max: 1920},
        height: {ideal: 768, max: 1080},
    },
    preferCurrentTab: true,
};

export const HostScreenSharingStreamConstraints = {
    audio: true,
    video: {
        width: {ideal: 1920, max: 1920},
        height: {ideal: 1080, max: 1080},
    },
    preferCurrentTab: true,
};

export const RtcPeerConnectionConfiguration = {
    iceServers: [
        {
            urls: process.env.REACT_APP_KURENTO_TURN_URL,
            username: process.env.REACT_APP_KURENTO_TURN_USERNAME,
            credential: process.env.REACT_APP_KURENTO_TURN_CREDENTIAL,
        },
    ],
    iceTransportPolicy: 'relay',
};
