import { AppointmentParticipantDetailsDTO, AvetisovMatsResult } from '@api/mainServiceAPI';
import { Chat, VideoParticipant } from '@components';
import { getProcedureTargetPoints } from '@configProcedures';
import { ProcedureStatus, ProcedureTypeEnum } from '@enums';
import {ChatWithDotsBlackIcon, CloseIcon, ExpandIcon, ProfileIcon} from '@icons';
import { IChatMessage, IChatMessageDto, ParticipantProcedure, ProcedureSettingsValueType } from '@models';
import { selectCurrentProfile } from '@sliceUser';
import { WsProcedureTopicType } from '@utils/websocket.topics';
import { Badge, Button, Col, Row } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WebsocketContext } from 'src/contexts/ws.context';
import { IProcedureResult } from 'src/models/procedure-result.model';
import { SessionParticipant } from 'src/models/session-participant';
import { SelectedParticipantTabType } from '../../DoctorAppointmentPage';
import { ProcedureHostChip } from '../ProcedureHostChip/ProcedureHostChip';
import { ProcedureSettingsWizard } from '../ProcedureSettingsWizard/ProcedureSettingsWizard';
import styles from './HostSidebar.module.scss';
import { PatientDocumentsTab } from './components/PatientDocumentsTab/PatientDocumentsTab';
import { PatientInfoTab } from './components/PatientInfoTab/PatientInfoTab';

interface IHostSidebarProps {
    participants: SessionParticipant[];
    startedTime: Date | null;
    selectedParticipant?: SessionParticipant;
    selectedParticipantTab: SelectedParticipantTabType;
    host: SessionParticipant;
    appointmentDetails?: AppointmentParticipantDetailsDTO;
    someProceduresInProgress: boolean;

    saveComment: (comment: string) => void;
    stopProceduresForAll: () => void;
    changeStatusProcedure: (procedureStatus: ProcedureStatus, procedure: ParticipantProcedure) => void;
    commentProcedureResult: (result: IProcedureResult, procedureId?: string) => void;
    onSetSelectedParticipant: (participant?: SessionParticipant) => void;
    onSetSelectedParticipantTab: (tab: SelectedParticipantTabType) => void;
    onApproveFromLobby: (fhirIds: string[]) => void;
    closeSession: () => void;
    isPatientFullScreen?: boolean;
    onExpand?: () => void;
}

export const HostSidebar = ({
    participants,
    startedTime,
    selectedParticipant,
    selectedParticipantTab,
    host,
    appointmentDetails,
    someProceduresInProgress,
    isPatientFullScreen = false,
    onExpand,
    saveComment,
    stopProceduresForAll,
    changeStatusProcedure,
    commentProcedureResult,
    onSetSelectedParticipant,
    onSetSelectedParticipantTab,
    onApproveFromLobby,
    closeSession,
}: IHostSidebarProps) => {
    const { t } = useTranslation();
    const { send } = useContext(WebsocketContext);

    const currentProfile = useSelector(selectCurrentProfile);

    const [connectionTime, setConnectionTime] = useState('');
    const [procedureIdForSettings, setProcedureIdForSettings] = useState<string>('');
    const [tabs, setTabs] = useState<SelectedParticipantTabType[]>([]);
    const [chatNewMessagesCount, setChatNewMessagesCount] = useState<number>(0);

    const [accommodationCapacityHistory, setAccommodationCapacityHistory] = useState<AvetisovMatsResult[]>([]);

    useEffect(() => {
        if (startedTime) {
            // just show current connection time
            const connectionTimeInterval = setInterval(() => {
                const milliseconds = new Date().getTime() - startedTime.getTime();
                setConnectionTime(new Date(milliseconds).toISOString().slice(11, 19));
            }, 1000);

            return () => {
                clearInterval(connectionTimeInterval);
            };
        }
    }, [startedTime]);

    useEffect(() => {
        switch (appointmentDetails?.serviceType) {
            case 'consultation':
            case 'diagnostics':
                setTabs(['info', 'documents', 'chat']);
                break;
            case 'therapy-session':
                setTabs(['info', 'procedures', 'chat']);
                break;
            default:
                break;
        }
    }, [appointmentDetails?.serviceType]);

    useEffect(() => {
        setAccommodationCapacityHistory((selectedParticipant?.carePlanDTO?.accommodationCapacityHistory || [])?.reverse());
    }, [selectedParticipant]);

    useEffect(() => {
        setChatNewMessagesCount(selectedParticipant?.chatHistory.filter((x) => x.receiverId === currentProfile?.fhirId && !x.viewed).length || 0);
    }, [selectedParticipant, currentProfile, connectionTime]); // every second

    const processProcedureSettings = (result?: Partial<ProcedureSettingsValueType>) => {
        if (result) {
            const targetProcedure = selectedParticipant?.procedures.find((x) => x.id === procedureIdForSettings);

            if (targetProcedure) {
                targetProcedure.settings = result;

                // recalculate target points
                targetProcedure.targetPoints = result.duration ? getProcedureTargetPoints(+result.duration, targetProcedure.type!) : 0;
            }
        }

        setProcedureIdForSettings('');
    };

    const sendChatMessage = (messageText: string): void => {
        const message: IChatMessage = {
            id: nanoid(),
            text: messageText,
            createdDate: new Date(),
            senderId: currentProfile?.fhirId,
            receiverId: selectedParticipant?.fhirId,
        };

        send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, {
            type: WsProcedureTopicType.chatMessage,
            message,
        });

        selectedParticipant!.chatHistory = [...selectedParticipant!.chatHistory, message];

        // to save message in the history
        if (selectedParticipant?.chatId) {
            const messageDto: IChatMessageDto = {
                type: 'NEW_MSG',
                conversationId: selectedParticipant?.chatId,
                payload: messageText,
                sender: currentProfile?.fhirId as any,
            };

            send('/msg/chat/message', '/chat/' + selectedParticipant?.chatId, messageDto);
        }
    };

    const markChatMessageAsRead = (messageId: string): void => {
        const message: IChatMessage = {
            id: messageId,
            senderId: currentProfile?.fhirId,
        };

        send('/msg/gateway-ws/message', '/procedure/' + selectedParticipant?.fhirId, {
            type: WsProcedureTopicType.messageViewed,
            message,
        });

        // to save message in the history
        send('/msg/chat/message', '/chat/' + selectedParticipant?.chatId, {
            type: 'MSG_SET_READ',
            conversationId: selectedParticipant?.chatId,
            messageId,
            sender: currentProfile?.fhirId,
            read: true,
        });

        const targetMessage = selectedParticipant?.chatHistory.find((x) => x.id === message.id);
        if (targetMessage) {
            targetMessage.viewed = true;
        }
    };

    const renderGeneralAsideToolbar = () => {
        const inLobby = participants.filter((x) => x.inLobby && !x.kurentoParticipant).map((x) => x.fhirId!);
        const participantsHereCount = participants.filter((x) => !x.inLobby && !!x.kurentoParticipant).length;

        const appointmentDate = dayjs(appointmentDetails?.startTimestamp);
        const appointmentDateFormatted = appointmentDate.format('dddd, D MMMM, HH:mm');

        return (
            <div className={styles.aside_general}>
                <div className={styles.general_header}>
                    <span className={styles.general_subtitle}>{appointmentDateFormatted}</span>
                    {appointmentDetails?.planDefinitionID && (
                        <span className={styles.general_title}>{t('enums.planDefinition.' + appointmentDetails?.planDefinitionID)}</span>
                    )}
                </div>

                <div className={styles.general_patients}>
                    <div>
                        <span>{t("host_sidebar.all_patients_count")}:</span> <span className={styles.general_counter}>{participants.length}</span>
                    </div>
                    <div>
                        <span>{t("host_sidebar.connected_patients_count")}:</span> <span className={styles.general_counter}>{participantsHereCount}</span>
                    </div>
                    <div>
                        <span>{t("host_sidebar.wait_room_patients_count")}:</span> <span className={styles.general_counter}>{inLobby.length}</span>
                    </div>
                </div>

                <div className={styles.general_buttons}>
                    <Button type="primary" disabled={!inLobby.length} onClick={() => onApproveFromLobby(inLobby)}>
                        {t("host_sidebar.connect_all")}:
                    </Button>
                    <Button disabled={!someProceduresInProgress} onClick={stopProceduresForAll}>
                        {t("host_sidebar.disconnect_all")}:
                    </Button>
                </div>
            </div>
        );
    };

    const renderSelectedParticipantAside = () => {
        if (!selectedParticipantTab || !selectedParticipant) {
            return null;
        }

        const renderContent = () => {
            switch (selectedParticipantTab) {
                case 'info':
                    return (
                        <div className="p-4">
                            <PatientInfoTab
                                serviceType={appointmentDetails?.serviceType}
                                currentProfile={currentProfile}
                                patient={selectedParticipant}
                                saveComment={saveComment}
                            />
                        </div>
                    );
                case 'procedures':
                    return <div className={classNames('p-4', styles.proceduresContainer)}>{renderAsideProceduresTab()}</div>;
                case 'documents':
                    return (
                        <div className="p-4">
                            <PatientDocumentsTab patient={selectedParticipant} />
                        </div>
                    );
                case 'chat':
                    return (
                        <Chat
                            messages={selectedParticipant?.chatHistory}
                            currentUserId={currentProfile?.fhirId}
                            placeholder={t('appointmentPage.chat.messageToPatient')}
                            noMessagesLabel={t('appointmentPage.chat.descriptionForNurse')}
                            sendMessage={(e) => sendChatMessage(e)}
                            markAsRead={markChatMessageAsRead}
                        />
                    );
                default:
                    return null;
            }
        };

        return (
            <div className={styles.aside_content_wrapper}>
                <div className={classNames(styles.aside_header, 'mb-2')}>
                    {appointmentDetails?.serviceType === 'therapy-session' && selectedParticipant && (
                        <div className={classNames('d-flex align-items-center justify-content-between', styles.patientHeader)}>
                            <ProfileIcon className={styles.profileIcon} />
                            <span className={styles.patientTitle}>
                                <div className={styles.title}>{selectedParticipant.title}</div>
                                <div className={styles.subTitle}>{selectedParticipant.subtitle}</div>
                            </span>

                            <Button
                                type="link"
                                size="small"
                                icon={<CloseIcon className={styles.darkCross} />}
                                onClick={() => {
                                    selectedParticipant.select(null);
                                    onSetSelectedParticipant(undefined);
                                    onSetSelectedParticipantTab(null);
                                }}
                            ></Button>
                        </div>
                    )}

                    <div className={styles.aside_tabs}>
                        {tabs.map((x, i) => (
                            <button
                                key={i}
                                type="button"
                                className={classNames(styles.aside_tabs_tab, selectedParticipantTab === x && styles.active_tab)}
                                onClick={() => onSetSelectedParticipantTab(x)}
                            >
                                {t('appointmentPage.labels.' + x)}
                                {x === 'chat' && chatNewMessagesCount > 0 && (
                                    <Badge className={styles.badge} size="small" color="#F64D4D" count={chatNewMessagesCount}></Badge>
                                )}
                            </button>
                        ))}
                    </div>
                </div>

                <div className={styles.aside_content}>{renderContent()}</div>
            </div>
        );
    };

    const renderAsideProceduresTab = () => {
        if (!selectedParticipant?.procedures.length) {
            return null;
        }

        const procedureForSettings = selectedParticipant.procedures.find((x) => x.id === procedureIdForSettings);

        return (
            <>
                {procedureForSettings && (
                    <ProcedureSettingsWizard
                        procedureType={procedureForSettings.type!}
                        submitEvent={processProcedureSettings}
                        values={procedureForSettings.settings}
                    />
                )}
                <div className={classNames('p-4', styles.proceduresList, procedureForSettings && styles.invisible)}>
                    <div className={styles.aside_procedures_header}>
                        <span>
                            {t("host_sidebar.day")} {selectedParticipant.currentSessionDay} {t("host_sidebar.from")} {selectedParticipant?.carePlanDTO?.durationInDays}
                        </span>
                        <span className={styles.occlusion}>
                            {selectedParticipant.carePlanDTO?.needGlasses ? t("host_sidebar.with_glasses") : t("host_sidebar.without_glasses")}
                            {selectedParticipant.carePlanDTO?.targetEye && (
                                <>, {t('enums.targetEye.' + selectedParticipant.carePlanDTO?.targetEye)}</>
                            )}
                            {selectedParticipant.currentSessionOcclusionEye && (
                                <>, {t('enums.targetEye.' + selectedParticipant.currentSessionOcclusionEye)} {t("host_sidebar.occlusion")}</>
                            )}
                        </span>
                    </div>

                    {selectedParticipant.carePlanDTO && selectedParticipant.carePlanDTO.notesForNurse && <div
                        className={classNames(styles.bubble, styles.blueBubble, 'w-100 my-3')}
                    >
                        <div className="d-flex align-items-start">
                            <div className="position-relative">
                                <ChatWithDotsBlackIcon />
                            </div>
                            <div className={styles['comment-icon-space']}>
                                <p className={styles.commentDoctor}>{selectedParticipant.carePlanDTO?.notesForNurse}</p>
                            </div>
                        </div>
                    </div>}

                    <ul className={styles.aside_procedures}>
                        {selectedParticipant.procedures.map((p, i) => {
                            const doneProcedure = p.status === ProcedureStatus.finished || p.doctorConfirmed;

                            // you can change only the active procedure
                            const disabled = selectedParticipant.procedures
                                .filter((x) => x.id !== p.id)
                                .some(
                                    (x) =>
                                        x.status === ProcedureStatus.inProgress ||
                                        x.status === ProcedureStatus.continued ||
                                        x.status === ProcedureStatus.paused ||
                                        x.status === ProcedureStatus.waitingApproveToChangeGlasses ||
                                        x.status === ProcedureStatus.changeGlasses ||
                                        x.status === ProcedureStatus.started,
                                );

                            return (
                                <li key={i}>
                                    <ProcedureHostChip
                                        procedure={p}
                                        isClosest={i === 0}
                                        changeStatusEvent={(status) => changeStatusProcedure(status, p)}
                                        doctorConfirmEvent={(result) => commentProcedureResult(result, p.id)}
                                        doctorComment={p.doctorComment}
                                        expandable={doneProcedure}
                                        expanded={!doneProcedure}
                                        needOpenSettings={(id) => setProcedureIdForSettings(id)}
                                        settings={p.settings}
                                        viewOnly={!selectedParticipant.kurentoParticipant}
                                        disabled={disabled}
                                        accommodationCapacityCurrent={p.accommodationCapacity}
                                        accommodationCapacityHistory={p.type === ProcedureTypeEnum.AVETISOV_MATS ? accommodationCapacityHistory : []}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </>
        );
    };

    return (
        <div className={styles.wrapper}>
            <Row justify="space-between" className={styles.title}>
                <Col className="d-flex align-items-center gap-3">
                    {host.kurentoParticipant?.screenSharingActive && <span className={styles.indicator}></span>}
                    <span className={styles.connectionTime}>{connectionTime}</span>
                </Col>
                <Col>
                    <Button className={styles.closeBtn} type="default" icon={<CloseIcon />} onClick={() => closeSession()}></Button>
                </Col>
            </Row>
            <div className={styles.aside_host}>
                {appointmentDetails?.serviceType === 'therapy-session' ? (
                    <VideoParticipant participant={host} hostView={true} manageable={true} />
                ) : (
                    <div className={classNames(styles.diagnostic_video, isPatientFullScreen ? 'd-none' : 'd-block')}>
                        {selectedParticipant?.kurentoParticipant && !isPatientFullScreen && (
                            <VideoParticipant
                                participant={selectedParticipant}
                                hostView={true}
                                diagnosticView={true}
                                manageable={true}
                                serviceType={appointmentDetails?.serviceType}
                            />
                        )}
                        <div className={classNames(selectedParticipant?.kurentoParticipant ? styles.host_preview : styles.diagnostic_video)}>
                            <VideoParticipant participant={host} hostView={true} withoutTitles={true} />
                        </div>
                        <Button
                            onClick={() => onExpand && onExpand()}
                            className={classNames(styles.expand, selectedParticipant?.kurentoParticipant ? 'd-flex' : 'd-none')}
                        >
                            <ExpandIcon />
                        </Button>
                    </div>
                )}
            </div>

            {appointmentDetails?.serviceType === 'therapy-session'
                ? selectedParticipant
                    ? renderSelectedParticipantAside()
                    : renderGeneralAsideToolbar()
                : renderSelectedParticipantAside()}
        </div>
    );
};
