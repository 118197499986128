import styles from "../LockMobilePage/LockMobilePage.module.scss";
import {Logo} from "../../../components/Logo/Logo";
import {Alert} from "@icons";
import {t} from "i18next";
import {Button} from "antd";
import {REACT_APP_SUPPORT_PHONE} from "@utils/constants";
import {useEffect, useState} from "react";

export const LockMobilePage = () => {

    const [landingPageUrl, setLandingPageUrl] = useState<string | null>(null);

    useEffect(() => {
        const landingLocation: string[] = window.location.hostname.split(".");
        if(landingLocation.length === 3) {
            landingLocation.shift();
            setLandingPageUrl(landingLocation.join("."));
        }
    }, []);

    return (
        <>
            <main className={styles.wrapper}>
                <section className={styles.subWrapper}>
                    <div className={styles.mobileCenterContainer}>
                        <div className={styles.mobileCenterSubContainer}>
                                <Logo big/>
                            <div className={styles.info}>
                                <Alert/>
                                <h4>{t("lockMobilePage.header")}</h4>
                                <span className={styles.notice}>{t("lockMobilePage.notice")}</span>
                                {landingPageUrl &&
                                    <Button target={"_blank"} href={landingPageUrl} type="link">
                                    {t("lockMobilePage.toMainPageButton")}
                                    </Button>
                                }
                            </div>
                            <div className={styles.footer}>
                                <span>{t("lockMobilePage.callForSupportNotice")}</span>
                                <a className={styles.phone}
                                   href={"tel:" + REACT_APP_SUPPORT_PHONE}>{REACT_APP_SUPPORT_PHONE}</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};
