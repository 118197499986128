import {getPractitionersSlots, SlotDTO} from '@api/mainServiceAPI';
import dayjs from 'dayjs';
import Scheduler, {Resource} from 'devextreme-react/scheduler';
import React, {ComponentType, RefObject, useEffect, useState} from 'react';
import {ISchedulerSlot} from '../ClinicAdminSchedule';
import {END_DAY_TIME} from "@utils/constants";
import {t} from "i18next";

interface ISchedulerMemoFcProps {
    schedulerRef: RefObject<Scheduler>;
    currentDate: Date;
    currentView: 'agenda' | 'day' | 'week' | 'month';
    handleReadyChanged: () => void;
    renderAppointment: (data: { appointmentData: ISchedulerSlot }) => JSX.Element;
    onCellClick: (e: any) => void;
    onAppointmentClick: (data: any) => void;
    renderAppointmentCollectorComponent: ComponentType<any> | undefined;
    renderAppointmentCollector: (data: { appointmentData: ISchedulerSlot }) => JSX.Element;

    selectedPractitionerIds?: string[];
    selectedDay?: Date;
}

const SchedulerMemoFc = ({
                             schedulerRef,
                             currentDate,
                             currentView,
                             handleReadyChanged,
                             renderAppointment,
                             onCellClick,
                             onAppointmentClick,
                             renderAppointmentCollector,
                             renderAppointmentCollectorComponent,

                             selectedPractitionerIds,
                             selectedDay
                         }: ISchedulerMemoFcProps) => {

    const [slots, setSlots] = useState<SlotDTO[]>([]);
    const [practitioners, setPractitioners] = useState<any>([]);
    const [reloading, setReloading] = useState<boolean>(false);

    useEffect(() => {
        setReloading(true);
        if (selectedDay && selectedPractitionerIds) {
            currentDate = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate(), 0, 0, 0, 0);
            getPractitionersSlots({start: currentDate.getTime(), end: currentDate.getTime() + END_DAY_TIME}).then((res) => {
                setSlots(res.data.filter(f => selectedPractitionerIds.includes(f.practitionerId!)).flatMap((x) => x.slots!));
                setPractitioners(res.data.filter(f => selectedPractitionerIds.includes(f.practitionerId!)).map((x) => ({id: x.practitionerId, text: x.practitionerName + " " + "(" + t("adminEmployeePage.profilePage.serviceInformation.priceCategory." + x.practitionerCategory) + " " + t("adminEmployeePage.profilePage.serviceInformation.priceCategory.category") + ")"})));
                setReloading(false);
            });
        }
    }, [selectedPractitionerIds, selectedDay]);

    const currentDateChangeHandler = (value: any) => {
        console.log({dej: value});
    }

    const groups = ['practitionerId'];

    return !reloading ? (
        <Scheduler
            groups={groups}
            ref={schedulerRef}
            currentDate={selectedDay}
            currentView={currentView}
            firstDayOfWeek={1}
            showAllDayPanel={false}
            useDropDownViewSwitcher={false}
            editing={false}
            dataSource={slots?.map((slot) => ({
                ...slot,
                name: `${slot?.practitionerName}`,
                role: ``,
                id: slot.id,
                startDate: dayjs(slot.startDateTime).toDate(),
                endDate: dayjs(slot.endDateTime).toDate(),
                patientsNumber: slot.patientsNumber,
                duration: dayjs(slot.endDateTime).diff(dayjs(slot.startDateTime), 'minute'),
            }))}
            onCurrentDateChange={currentDateChangeHandler}
            defaultCurrentView={'day'}
            onContentReady={handleReadyChanged}
            appointmentRender={renderAppointment}
            onCellClick={onCellClick}
            onAppointmentClick={onAppointmentClick}
            onAppointmentDblClick={onCellClick}
            appointmentTooltipRender={renderAppointmentCollector}
            appointmentCollectorComponent={renderAppointmentCollectorComponent}
            onAppointmentContextMenu={onAppointmentClick}
            onAppointmentFormOpening={onAppointmentClick}
        >
            <Resource fieldExpr="practitionerId" dataSource={practitioners}/>
        </Scheduler>
    ) : <></>;
};

const isTheSame = (prevProps: ISchedulerMemoFcProps, nextProps: ISchedulerMemoFcProps): boolean => {
    return prevProps.selectedDay === nextProps.selectedDay && prevProps.currentView === nextProps.currentView;
};

export const SchedulerGroupMemo = React.memo(SchedulerMemoFc, isTheSame);