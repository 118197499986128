import { ConsultationAppointmentDetailsDTO, ConsultationAppointmentDetailsDTOEncounterStatus } from '@api/mainServiceAPI';
import { CountdownTimer } from '@components';
import { PaymentType } from '@enums';
import { Alert, Bubble, CalendarIcon, CheckRound, ClockIcon, Dino2_1, Dino6, PaymentIcon, Time, TriangleRight, TriangleWarning } from '@icons';
import { selectEntityToCheckPayment, selectPatientWorkspacePrepared } from '@slicePatient';
import { selectCurrentProfile } from '@sliceUser';
import { currencyFormatter } from '@utils/utils';
import { Avatar, Button, Col, Radio, RadioChangeEvent, Row, Space } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATIENT_TIME_TO_PAY } from 'src/ADMINISTRATIVE_CONFIG';
import { PaymentDirectlyModal } from 'src/modals/PaymentDirectlyModal/PaymentDirectlyModal';
import { WorkspaceSettings } from '../WorkspaceSettings/WorkspaceSettings';
import styles from './ConsultationBlock.module.scss';
import {selectPaymentServiceActive} from "../../../../../store/organization";

export interface IConsultationBlockProps {
    consultation: ConsultationAppointmentDetailsDTO;
    openDocInfo: (docId: string) => void;
    pay: (appointmentFhirId: string, paymentType: PaymentType) => void;
}

export const ConsultationBlock = ({ consultation, openDocInfo, pay }: IConsultationBlockProps) => {
    const { t } = useTranslation();

    const currentProfile = useSelector(selectCurrentProfile);
    const patientWorkspacePrepared = useSelector(selectPatientWorkspacePrepared);
    const paymentServiceActive = useSelector(selectPaymentServiceActive);
    const entityToCheckPayment = useSelector(selectEntityToCheckPayment);

    const [showPaymentDirectlyModal, setShowPaymentDirectlyModal] = useState(false);

    const [dateLabel, setDateLabel] = useState<string>();
    const [timeLabel, setTimeLabel] = useState<string>();

    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.MAIN_COUNTRY);

    useEffect(() => {
        if (consultation) {
            setDateLabel(dayjs(consultation.dateTime).format('dddd, D MMMM'));
            setTimeLabel(dayjs(consultation.dateTime).format('HH:mm'));
        }
    }, [consultation]);

    const onChangePaymentType = (e: RadioChangeEvent) => {
        setPaymentType(e.target.value);
    };

    return (
        <>
            {consultation.encounterStatus === ConsultationAppointmentDetailsDTOEncounterStatus.onleave ? (
                <div className={styles.consultationFinishedBlock}>
                    <div>
                        <h2>{t('consultation_block.consultation_completed')}</h2>
                        <p className={styles.description}>{t('consultation_block.hope_you_enjoyed')}</p>

                        <div className="d-flex" style={{ marginTop: '32px' }}>
                            <ClockIcon />
                            <p className={styles.waitDoctorResultText}>{t('consultation_block.doctor_preparing_report')}</p>
                        </div>
                    </div>
                    <div>
                        <div className={styles.dino}>
                            <Dino2_1 />
                            <div className={styles.bubble}>
                                <p className={styles.title}>{t('consultation_block.return_with_results_soon')}</p>
                                <Bubble />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classNames(styles['border-container'], styles.consultationBordered)}>
                    <div className={styles.content}>
                        <h6 className={styles.title}>
                            {t('userMainPage.singleConsultation.title')}

                            <Link className={styles.linkBtn} to={'/appointments/' + consultation.encounterFhirId}>
                                {t('details_link')}
                                <TriangleRight />
                            </Link>
                        </h6>

                        <Row
                            className={classNames(
                                consultation.paymentStatus === 'pending' || consultation.paymentStatus === 'completed',
                                'align-items-center',
                            )}
                        >
                            <Col span={10}>
                                <Space size={16} align="start">
                                    <Avatar src={consultation.practitionerDTO?.photo} size={74} />
                                    <div className={styles.doctorBlock}>
                                        <div className={classNames(styles.title, 'mb-0')}>{consultation.practitionerDTO?.fullName}</div>
                                        <div className={styles.subtitle}>{t('enums.speciality.' + consultation.practitionerDTO?.speciality)}</div>

                                        <Button
                                            type="ghost"
                                            className={`${styles.link} d-flex align-items-center mt-1`}
                                            onClick={() => openDocInfo(consultation.practitionerDTO!.id!)}
                                        >
                                            {t('userMainPage.singleConsultation.moreAboutDoctor')} <TriangleRight />
                                        </Button>
                                    </div>
                                </Space>
                            </Col>
                            <Col span={14}>
                                <Space size={16} align="center" className={classNames('w-100', styles.flex4section)}>
                                    <CalendarIcon />
                                    <div className={styles.timeBlock}>
                                        <div className={styles.date}>{dateLabel}</div>
                                        <div className={styles.time}>{timeLabel}</div>
                                        <Link className={styles.link} to={'/appointments/' + consultation.encounterFhirId}>
                                            {t('userMainPage.singleConsultation.change')}
                                        </Link>
                                    </div>

                                    {consultation.paymentStatus === 'pending' && (
                                        <>
                                            {entityToCheckPayment?.id === consultation.appointmentFhirId ? (
                                                <>
                                                    <ClockIcon />
                                                    <div className={styles.statusBlock}>
                                                        <div className={classNames(styles.title, 'mb-0')}>
                                                            {t('userMainPage.singleConsultation.paymentChecking')}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <Alert />
                                                    <div className={styles.statusBlock}>
                                                        <div className={classNames(styles.title, 'mb-0')}>
                                                            {t('userMainPage.singleConsultation.notPayed')}
                                                        </div>
                                                        <div>
                                                            {t('userMainPage.singleConsultation.toPay')} {paymentType !== PaymentType.PRODAMUS ? currencyFormatter(consultation.price) : currencyFormatter(Number(consultation.price) * 1.1)}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}

                                    {consultation.paymentStatus === 'completed' && (
                                        <>
                                            {consultation.status === 'booked' || consultation.status === 'arrived' ? (
                                                !patientWorkspacePrepared ? (
                                                    <>
                                                        <CheckRound />
                                                        <div className={styles.statusBlock}>
                                                            <div className={classNames(styles.title, 'mb-0')}>
                                                                {t('userMainPage.singleConsultation.confirmed')}
                                                            </div>
                                                            <div>{t('userMainPage.singleConsultation.pleasePrepare')}</div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className={styles['dino-ready']}>
                                                        <Dino6 />
                                                        <div className={styles.bubble}>
                                                            <p className={styles.title} style={{ marginLeft: '-25px' }}>
                                                                {t('consultation_block.everything_ready')}
                                                            </p>
                                                            <Bubble />
                                                        </div>
                                                    </div>
                                                )
                                            ) : consultation.status === 'cancelled' ? (
                                                <>
                                                    <Alert />
                                                    <div className={styles.statusBlock}>
                                                        <div className={classNames(styles.title, 'mb-0')}>
                                                            {t('consultation_block.consultation_cancelled')}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <Time />
                                                    <div className={styles.statusBlock}>
                                                        <div className={classNames(styles.title, 'mb-0')}>
                                                            { paymentServiceActive ? t('userMainPage.singleConsultation.payed') : t('userMainPage.singleConsultation.created')}
                                                        </div>
                                                        <div>{t('userMainPage.singleConsultation.waitForConfirmation')}</div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Space>
                            </Col>
                        </Row>

                        {consultation.paymentStatus === 'pending' && (
                            <>
                                <CountdownTimer
                                    timeX={new Date((consultation.paymentRegisteredAt! || 0) + PATIENT_TIME_TO_PAY * 1000)}
                                    before={PATIENT_TIME_TO_PAY}
                                    render={(timeLabel: string) => (
                                        <div>
                                            <div className={classNames(styles.divider, 'mt-5')} />
                                            <div className={styles.bottomSection}>
                                                <h6 className={styles.title}>{t('userMainPage.singleConsultation.payment')}</h6>
                                                <Row justify="space-between">
                                                    <Col span={10} className="d-flex align-items-center">
                                                        <Space size={16}>
                                                            <PaymentIcon />
                                                            <p>{t('userMainPage.singleConsultation.payWarning', { time: timeLabel })}</p>
                                                        </Space>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-4">
                                                            <Radio.Group
                                                                value={paymentType}
                                                                onChange={onChangePaymentType}
                                                                disabled={entityToCheckPayment?.id === consultation.appointmentFhirId}
                                                            >
                                                                <Space direction="vertical">
                                                                    <Radio value={PaymentType.MAIN_COUNTRY} className="mb-3">
                                                                        {t(`consultation_sign_up.payment_step.${PaymentType.MAIN_COUNTRY}`)}
                                                                    </Radio>
                                                                    <Radio value={PaymentType.PRODAMUS} className="mb-3">
                                                                        {t(`consultation_sign_up.payment_step.${PaymentType.PRODAMUS}`)}
                                                                    </Radio>
                                                                    <Radio value={PaymentType.MTS} className="mb-3">
                                                                        {t(`consultation_sign_up.payment_step.${PaymentType.MTS}`)}
                                                                    </Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </div>
                                                        <Button
                                                            disabled={entityToCheckPayment?.id === consultation.appointmentFhirId}
                                                            onClick={() => pay(consultation.appointmentFhirId!, paymentType)}
                                                            size="large"
                                                            type="primary"
                                                        >
                                                            {t('userMainPage.singleConsultation.toPayBtn')} {paymentType !== PaymentType.PRODAMUS ? currencyFormatter(consultation.price) : currencyFormatter(Number(consultation.price) * 1.1)}
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <div className={styles['directly-payment-block']}>
                                                    <div className={styles['warning-icon']}>
                                                        <TriangleWarning />
                                                    </div>
                                                    <p>
                                                        {t('consultation_sign_up.payment_step.contact_manager_if_paid')}{' '}
                                                        <span onClick={() => setShowPaymentDirectlyModal(true)}>
                                                            {t('consultation_sign_up.payment_step.in_instruction')}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    renderAfter={() => (
                                        <div>
                                            <div className={classNames(styles.divider, 'mt-5')} />
                                            <div className={styles.bottomSection}>
                                                <div className={styles.cancelledInfo}>
                                                    <Alert />
                                                    <span>{t('consultation_sign_up.payment_step.payment_timeout')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                ></CountdownTimer>
                            </>
                        )}

                        {consultation.paymentStatus === 'failed' && (
                            <div>
                                <div className={classNames(styles.divider, 'mt-5')} />
                                <div className={styles.bottomSection}>
                                    <div className={styles.cancelledInfo}>
                                        <Alert />
                                        <span>{t('consultation_sign_up.payment_step.payment_error_call_support')}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!patientWorkspacePrepared &&
                            consultation.paymentStatus === 'completed' &&
                            (consultation.status === 'booked' || consultation.status === 'arrived') && (
                                <>
                                    <div className={classNames(styles.divider, 'mt-5')} />
                                    <div className={styles.bottomSection}></div>
                                    <WorkspaceSettings onlyForInitialSettings={true} profileId={currentProfile!.fhirId!} />
                                </>
                            )}
                    </div>

                    <PaymentDirectlyModal
                        visible={showPaymentDirectlyModal}
                        onClose={() => {
                            setShowPaymentDirectlyModal(false);
                        }}
                    />
                </div>
            )}
        </>
    );
};
