/* eslint-disable @typescript-eslint/no-explicit-any */

import {RuleObject} from 'antd/es/form';

export function openFullscreen() {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if ((elem as any).webkitRequestFullscreen) {
        /* Safari */
        (elem as any).webkitRequestFullscreen();
    } else if ((elem as any).msRequestFullscreen) {
        /* IE11 */
        (elem as any).msRequestFullscreen();
    }
}

export function exitFullscreen() {
    if (document.fullscreenElement) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
            // Firefox
            (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) {
            // Chrome, Safari, and Opera
            (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) {
            // Internet Explorer and Edge
            (document as any).msExitFullscreen();
        }
    }
}

export const dateForServer = (date: Date, withoutTime = true, utcTime = false): number => {
    if (!date) {
        return 0;
    }
    if (withoutTime) {
        date.setHours(0, 0, 0, 0);
    }

    return utcTime ? date.getTime() : new Date(date.getTime() - date.getTimezoneOffset() * 60000).getTime();
};

export function convertToUserLocalTime(date: Date): Date {
    const utcTimestamp = new Date().getTimezoneOffset() * 60000;
    return new Date(date.getTime() - utcTimestamp);
}

export const dateFromServer = (date: Date): Date => {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;

    return new Date(date.getTime() + userTimezoneOffset);
};

export const secondsAsTime = (seconds: number): string => {
    // Calculate the minutes and seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Format the minutes and seconds as strings with leading zeros if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

    // Concatenate the formatted minutes and seconds into the final string
    const formattedTime = `${formattedMinutes}:${formattedSeconds}`;

    return formattedTime;
};

export const currencyFormatter = (value?: number, local = 'ru-RU'): string => {
    if (value !== 0 && (value === undefined || isNaN(value))) {
        return value === 0 ? `${value}` : '';
    }
    const formatConfig = {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0,
        currencyDisplay: 'symbol',
    };
    return Intl.NumberFormat(local, formatConfig).format(value);
};

export const groupBy = (items: any[], key: string) =>
    items.reduce(
        (result: any, item: any) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
        }),
        {},
    );

export const pluralLabel = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

export const calculateAge = (birthDate: string) => Math.floor((new Date().getTime() - new Date(birthDate).getTime()) / (1000 * 60 * 60 * 24 * 365.25));

export function capitalizeText(text: string): string {
    const result = text.charAt(0).toUpperCase() + text.slice(1);
    return result;
}

export const flatObject = (prefixKey: string, props?: any, separator = '_') => {
    const result: any = {};

    if (props) {
        Object.keys(props).forEach((x) => {
            result[prefixKey + separator + x] = (props as any)[x];
        });
    }

    return result;
};

export const castToCustomNumber = (object: any): string => {
    object = !object ? null : String(object);
   return !object ? '0' : object.replace(/[^0-9.,+-]/g, '').replace(',', '.').replace(/^(\d)/, '+$1').replace(/(\..*?)[.,]+/g, '$1').replace('++', '+').replace(/^\+0$/ig, '0').replace(/^-0$/ig, '0')
};


export const getCoords = (elem: HTMLElement | null) => {
    if (!elem) {
        return;
    }
    // crossbrowser version
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    // const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    // const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
    // const top = box.top + scrollTop - clientTop;
    // const left = box.left + scrollLeft - clientLeft;

    const top = box.top - clientTop;
    const left = box.left - clientLeft;

    return {top: Math.round(top), left: Math.round(left)};
};

export const separateFileNameAndExtension = (fileName: string): { name: string; extension: string } => {
    const nameArr = fileName.split('.');
    const ext = nameArr.length > 1 ? (nameArr.pop() as string) : '';
    return {
        name: nameArr.length ? nameArr[0] : fileName,
        extension: ext,
    };
};

export function DEBUG(importantInformation: any): void {
    const {url} = importantInformation;

    if (url) {
        console.log(location.origin + '/' + url);
        navigator.clipboard.writeText(location.origin + '/' + url);
    }
}

export const customRequired = (msg: string, isRequired = true) => {
    return {
        validator: (_: RuleObject, value: any) => (value?.trim?.() || !isRequired ? Promise.resolve() : Promise.reject(new Error(msg))),
    };
};
