export enum AppointmentStatus {
    none = 'none',
    created = 'created',
    pending = 'pending',
    confirmed = 'confirmed',
    completed = 'completed',
    cancelled = 'cancelled',
    paymentFailed = 'paymentFailed',
    active = 'active',
}
