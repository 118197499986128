import {Button, DatePicker, Form, message} from 'antd';
import {useTranslation} from 'react-i18next';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {useFormValidation} from 'src/hooks/form-validation.hook';
import styles from './ClinicAdminAgentReportPage.module.scss';
import {getAgentReport, GetAgentReportParams} from "@api/documentServiceAPI";
import dayjs from "dayjs";
import {CalendarInputIcon} from "@icons";
import Papa from 'papaparse';

interface CsvFormat {
    orderNumber: string;
    paymentDate: string;
    patientFullName: string;
    pricePerUnit: string;
    quantity: string;
    serviceType: string;
    totalPrice: string;
}

export const ClinicAdminAgentReportPage = () => {
    const {t} = useTranslation();

    const [dateRangeForm] = Form.useForm<GetAgentReportParams>();
    const {validateForm} = useFormValidation(dateRangeForm);

    const csvHeaders = [
        {label: t('adminAgentReportPage.csv.headers.orderNumber'), key: 'orderNumber'},
        {label: t('adminAgentReportPage.csv.headers.paymentDate'), key: 'paymentDate'},
        {label: t('adminAgentReportPage.csv.headers.patientFullName'), key: 'patientFullName'},
        {label: t('adminAgentReportPage.csv.headers.serviceType'), key: 'serviceType'},
        {label: t('adminAgentReportPage.csv.headers.pricePerUnit'), key: 'pricePerUnit'},
        {label: t('adminAgentReportPage.csv.headers.quantity'), key: 'quantity'},
        {label: t('adminAgentReportPage.csv.headers.totalPrice'), key: 'totalPrice'},
    ];

    const createAgentReport = () => {
        const fieldsToCheck = ['fromPeriod', 'toPeriod'];
        validateForm(fieldsToCheck);
        const fromPeriod = dateRangeForm.getFieldValue("fromPeriod");
        const toPeriod = dateRangeForm.getFieldValue("toPeriod");
        getAgentReport({
            fromPeriod: dayjs(fromPeriod).toDate().getTime(),
            toPeriod: dayjs(toPeriod).toDate().getTime()
        }).then((res) => {
            if (res.data.length === 0) {
                message.info(t("adminAgentReportPage.noDataForPeriod"));
                return;
            }
            const data = res.data.map(m => {
                return {
                    orderNumber: m.orderNumber?.toFixed(),
                    paymentDate: dayjs(m.paymentDate).format("DD-MM-YYYY"),
                    patientFullName: m.patientFullName,
                    pricePerUnit: m.pricePerUnit,
                    quantity: m.quantity,
                    serviceType: t('adminAgentReportPage.serviceTypes.' + m.serviceType),
                    totalPrice: m.totalPrice?.replace("RUB", "₽")
                } as CsvFormat;
            });
            data.unshift({
                orderNumber: csvHeaders.map(m => m.label)[0],
                paymentDate: csvHeaders.map(m => m.label)[1],
                patientFullName: csvHeaders.map(m => m.label)[2],
                serviceType: csvHeaders.map(m => m.label)[3],
                pricePerUnit: csvHeaders.map(m => m.label)[4],
                quantity: csvHeaders.map(m => m.label)[5],
                totalPrice: csvHeaders.map(m => m.label)[6],
            });
            const csvString = Papa.unparse(data, {header: false, columns: csvHeaders.map(m => m.key)});
            downloadCSV(csvString);
        });
    }

    const downloadCSV = (csvString: string) => {
        const fromPeriod = dateRangeForm.getFieldValue("fromPeriod");
        const toPeriod = dateRangeForm.getFieldValue("toPeriod");
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'agent_report_' + dayjs(fromPeriod).format("DD_MM_YYYY") + '__' + dayjs(toPeriod).format("DD_MM_YYYY") + '.csv'); // Название файла
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={styles['wrapper']}>
            <div className="d-flex justify-content-between">
                <p className={styles.title}>{t('adminAgentReportPage.title')}</p>
            </div>
            <p className={styles.description}>{t('adminAgentReportPage.description')}</p>
            <Form
                form={dateRangeForm}
                onFinish={createAgentReport}
                name="deliveryAddressModal"
                layout="vertical"
                wrapperCol={{span: 24}}
                initialValues={{
                    fromPeriod: dayjs().startOf('month'),
                    toPeriod: dayjs(),
                }}
                autoComplete="off"
            >
                <div className="d-flex justify-content-start align-items-center gap-3 mt-4">
                    <Form.Item
                        name="fromPeriod"
                        label={t('adminAgentReportPage.startPeriod')}
                        rules={[
                            {required: true, message: t('adminAgentReportPage.startPeriodRequired')},
                        ]}
                    >
                        <DatePicker
                            autoComplete="off"
                            inputReadOnly
                            placeholder=""
                            className="w-100"
                            suffixIcon={<CalendarInputIcon/>}
                            disabledDate={(e) => e > dayjs()}
                        />
                    </Form.Item>
                    <Form.Item
                        name="toPeriod"
                        label={t('adminAgentReportPage.endPeriod')}
                        rules={[
                            {required: true, message: t('adminAgentReportPage.endPeriodRequired')},
                        ]}
                    >
                        <DatePicker
                            autoComplete="off"
                            inputReadOnly
                            placeholder=""
                            className="w-100"
                            suffixIcon={<CalendarInputIcon/>}
                            disabledDate={(e) => e > dayjs()}
                        />
                    </Form.Item>
                    <Button style={{marginTop: '-8px'}} type="primary" htmlType="submit">
                        {t('adminAgentReportPage.downloadButton')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
