import { getAuthUser, selectAuthUser, selectSwitchEndUserRedirect, switchToEndUser, UserRole } from '@sliceUser';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { DoctorAppointmentPage } from 'src/pages/_shared/DoctorAppointmentPage/DoctorAppointmentPage';
import { AnonymousLayout } from 'src/pages/Anonymous/_layout/AnonymousLayout';
import { AccountPage } from 'src/pages/Anonymous/AccountPage/AccountPage';
import { AccountSuccessPage } from 'src/pages/Anonymous/AccountSuccessPage/AccountSuccessPage';
import { ConfirmMobilePage } from 'src/pages/Anonymous/ConfirmMobilePage/ConfirmMobilePage';
import { EmailConfirmationPage } from 'src/pages/Anonymous/EmailConfirmationPage/EmailConfirmationPage';
import { LoginPage } from 'src/pages/Anonymous/LoginPage/LoginPage';
import { RegisterPage } from 'src/pages/Anonymous/RegisterPage/RegisterPage';
import { CallCenterManagerLayout } from 'src/pages/CallCenterManager/_layout/CallCenterManagerLayout';
import { CallCenterManagerAppointmentsPage } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/CallCenterManagerAppointmentsPage';
import { CallCenterManagerAllAppointmentsPage } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/components/CallCenterManagerAllAppointmentsPage/CallCenterManagerAllAppointmentsPage';
import { CallCenterManagerAppointmentEventsPage } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/components/CallCenterManagerAppointmentEventsPage/CallCenterManagerAppointmentEventsPage';
import { CallCenterManagerAppointmentPage } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/components/CallCenterManagerAppointmentPage/CallCenterManagerAppointmentPage';
import { ClinicAdminLayout } from 'src/pages/ClinicAdmin/_layout/ClinicAdminLayout';
import { ClinicAdminEmployeePage } from 'src/pages/ClinicAdmin/ClinicAdminEmployeePage/ClinicAdminEmployeePage';
import { ClinicAdminSchedule } from 'src/pages/ClinicAdmin/ClinicAdminSchedule/ClinicAdminSchedule';
import { ClinicAdminStaffPage } from 'src/pages/ClinicAdmin/ClinicAdminStaffPage/ClinicAdminStaffPage';
import { HeadPhysicianLayout } from 'src/pages/HeadPhysician/_layout/HeadPhysicianLayout';
import { HeadPhysicianAppointmentsPage } from 'src/pages/HeadPhysician/HeadPhysicianAppointmentsPage/HeadPhysicianAppointmentsPage';
import { HeadPhysicianPatientsPage } from 'src/pages/HeadPhysician/HeadPhysicianPatientsPage/HeadPhysicianPatientsPage';
import { HeadPhysicianStaffPage } from 'src/pages/HeadPhysician/HeadPhysicianStaffPage/HeadPhysicianStaffPage';
import { NurseLayout } from 'src/pages/Nurse/_layout/NurseLayout';
import { PatientLayout } from 'src/pages/Patient/_layout/PatientLayout';
import { ConsultationSignUpPage } from 'src/pages/Patient/ConsultationSignUpPage/ConsultationSignUpPage';
import { CreateSchedulePage } from 'src/pages/Patient/CreateSchedulePage/CreateSchedulePage';
import { PatientAppointmentItemPage } from 'src/pages/Patient/PatientAppointmentItemPage/PatientAppointmentItemPage';
import { PatientAppointmentPage } from 'src/pages/Patient/PatientAppointmentPage/PatientAppointmentPage';
import { PatientAppointmentsPage } from 'src/pages/Patient/PatientAppointmentsPage/PatientAppointmentsPage';
import { PatientDashboardPage } from 'src/pages/Patient/PatientDashboardPage/PatientDashboardPage';
import { PatientMedcard } from 'src/pages/PatientMedcard/PatientMedcard';
import { PractitionerLayout } from 'src/pages/Practitioner/_layout/PractitionerLayout';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';

import { PaymentType } from '@enums';
import { DoctorAppointmentsPage } from 'src/pages/_shared/DoctorAppointmentsPage/DoctorAppointmentsPage';
import { AccountInfoPage } from 'src/pages/AccountInfoPage/AccountInfoPage';
import { ChangePassword } from 'src/pages/Anonymous/ChangePassword/ChangePassword';
import { CallCenterManagerAllCoursesPage } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/components/CallCenterManagerAllCoursesPage/CallCenterManagerAllCoursesPage';
import { CallCenterManagerProcedurePage } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/components/CallCenterManagerProcedurePage/CallCenterManagerProcedurePage';
import { ClinicAdminPatientPage } from 'src/pages/ClinicAdmin/ClinicAdminPatientPage/ClinicAdminPatientPage';
import { ClinicAdminPatientsPage } from 'src/pages/ClinicAdmin/ClinicAdminPatientsPage/ClinicAdminPatientsPage';
import { ClinicAdminSettingsPage } from 'src/pages/ClinicAdmin/ClinicAdminSettingsPage/ClinicAdminSettingsPage';
import { HeadPhysicianAppointmentItemPage } from 'src/pages/HeadPhysician/HeadPhysicianAppointmentItemPage/HeadPhysicianAppointmentItemPage';
import { HeadPhysicianEmployeePage } from 'src/pages/HeadPhysician/HeadPhysicianEmployeePage/HeadPhysicianEmployeePage';
import { HeadPhysicianProcedurePage } from 'src/pages/HeadPhysician/HeadPhysicianProcedurePage/HeadPhysicianProcedurePage';
import { NurseAppointmentItemPage } from 'src/pages/Nurse/NurseAppointmentItemPage/NurseAppointmentItemPage';
import { PatientMedicalRecordPage } from 'src/pages/Patient/PatientMedicalRecord/PatientMedicalRecord';
import { PractitionerAppointmentItemPage } from 'src/pages/Practitioner/PractitionerAppointmentItemPage/PractitionerAppointmentItemPage';
import { PractitionerPatientsPage } from 'src/pages/Practitioner/PractitionerPatientsPage/PractitionerPatientsPage';
import { ProfilePage } from 'src/pages/ProfilePage/ProfilePage';
import { UserLayout } from 'src/pages/User/_layout/UserLayout';
import { CreatePatientPage } from 'src/pages/User/CreatePatientPage/CreatePatientPage';
import { UserProfilePage } from 'src/pages/User/UserProfilePage/UserProfilePage';
import styles from './MainPage.module.scss';
import {ClinicAdminAgentReportPage} from "../../ClinicAdmin/ClinicAdminAgentReportPage/ClinicAdminAgentReportPage";
// import { ProcedureCanvas } from '@components';
// import { AmblyopiaEyeEnum, ProcedureTypeEnum } from '@enums';
// import { DURATION_LIST_SECONDS } from '@configProcedures';

export const MainPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [initialLoading, setInitialLoading] = useState(true);
    const authUser = useSelector(selectAuthUser);
    const switchEndUserRedirect = useSelector(selectSwitchEndUserRedirect);
    // const currentProfile = useSelector(selectCurrentProfile);

    // const [colorPicker, setColorPicker] = useState<{
    //     red: number;
    //     blue: number;
    //     green: number;
    // }>({
    //     red: 250,
    //     blue: 150,
    //     green: 150,
    // });

    useEffect(() => {
        dispatch(getAuthUser({}));
    }, []);

    useEffect(() => {
        if (switchEndUserRedirect) {
            navigate(switchEndUserRedirect);

            setTimeout(() => {
                dispatch(switchToEndUser(null));
            });
        }
    }, [switchEndUserRedirect]);

    useEffect(() => {
        if (authUser) {
            setInitialLoading(false);
        }

        // it's a dirty hack
        // todo it's not a best solution, but the fastest, otherwise - we need to add one more NO httpOnly cookie on the backend
        if (authUser === null) {
            setInitialLoading(false);
        }
    }, [authUser]);

    // useEffect(() => {
    //     if (currentProfile) {
    //         setInitialLoading(false);
    //         const screenSettingsLS = ScreenSettingsLocalStorage.getScreenSettings(currentProfile.fhirId);

    //         if (screenSettingsLS) {
    //             setColorPicker(screenSettingsLS.settings.colors);
    //         }
    //     }
    // }, [currentProfile]);

    return (
        <>
            {!initialLoading && (
                <main className={styles.wrapper}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute isAllowed={!!authUser?.role}>
                                    {authUser?.role === UserRole.Patient ? (
                                        <PatientLayout />
                                    ) : authUser?.role === UserRole.End_user ? (
                                        <UserLayout />
                                    ) : authUser?.role === UserRole.Nurse ? (
                                        <NurseLayout />
                                    ) : authUser?.role === UserRole.Practitioner ? (
                                        <PractitionerLayout />
                                    ) : authUser?.role === UserRole.Head_physician ? (
                                        <HeadPhysicianLayout />
                                    ) : authUser?.role === UserRole.Clinic_administrator || authUser?.role === UserRole.Administrator ? (
                                        <ClinicAdminLayout />
                                    ) : authUser?.role === UserRole.Call_center_manager ? (
                                        <CallCenterManagerLayout />
                                    ) : null}
                                </ProtectedRoute>
                            }
                        >
                            {authUser?.role === UserRole.End_user ? (
                                <>
                                    {/* User's pages */}
                                    <Route index path="/" element={<Navigate to="profile" replace />}></Route>
                                    <Route path="create-patient" element={<CreatePatientPage />} />
                                    <Route path="profile" element={<UserProfilePage />} />
                                </>
                            ) : authUser?.role === UserRole.Patient ? (
                                <>
                                    {/* Patient's pages */}
                                    <Route index path="/" element={<Navigate to="dashboard" replace />}></Route>
                                    <Route path="dashboard" element={<PatientDashboardPage />} />
                                    <Route path="payment-result-ok" element={<PatientDashboardPage paymentInfo={{ success: true, paymentType: PaymentType.MAIN_COUNTRY }} />} />
                                    <Route path="payment-result-fail" element={<PatientDashboardPage paymentInfo={{ success: false, paymentType: PaymentType.MAIN_COUNTRY }} />} />
                                    <Route
                                        path="payment-result-prodamus-ok"
                                        element={<PatientDashboardPage paymentInfo={{ success: true, paymentType: PaymentType.PRODAMUS }} />}
                                    />
                                    <Route
                                        path="payment-result-prodamus-fail"
                                        element={<PatientDashboardPage paymentInfo={{ success: false, paymentType: PaymentType.PRODAMUS }} />}
                                    />
                                    <Route
                                        path="payment-result-mts-ok"
                                        element={<PatientDashboardPage paymentInfo={{ success: true, paymentType: PaymentType.MTS }} />}
                                    />
                                    <Route
                                        path="payment-result-mts-fail"
                                        element={<PatientDashboardPage paymentInfo={{ success: false, paymentType: PaymentType.MTS }} />}
                                    />
                                    <Route path="appointments" element={<PatientAppointmentsPage />} />
                                    <Route path="appointments/:appointmentId" element={<PatientAppointmentItemPage />} />
                                    {/* Video conference */}
                                    <Route path="appointment" element={<PatientAppointmentPage />} />
                                    <Route path="account-edit" element={<AccountPage edit={true} />} />
                                    <Route path="consultation" element={<ConsultationSignUpPage />} />
                                    <Route path="create-schedule" element={<CreateSchedulePage />} />
                                    <Route path="medcard" element={<PatientMedicalRecordPage />} />
                                    {/* <Route
                                        path="canvas"
                                        element={
                                            <ProcedureCanvas
                                                colors={colorPicker}
                                                procedure={{
                                                    id: 'test1',
                                                    type: ProcedureTypeEnum.RESTORATION_OF_FUSION,
                                                    settings: {
                                                        visualAcuity: 0.1,
                                                        frequency: 0.5,
                                                        duration: DURATION_LIST_SECONDS[0],
                                                        angle: 45,
                                                        // occlusionEye: AmblyopiaEyeEnum.OD,
                                                        accuracy: 2,
                                                    },
                                                }}
                                                dpi={100}
                                                distance={0.6}
                                                interactionEvent={() => {}}
                                                paused={false}
                                            />
                                        }
                                    /> */}
                                </>
                            ) : authUser?.role === UserRole.Nurse ? (
                                <>
                                    {/* Nurse's pages */}
                                    <Route index path="/" element={<Navigate to="appointments" replace />}></Route>
                                    <Route path="appointments/:appointmentId" element={<NurseAppointmentItemPage />} />
                                    <Route path="procedures/:encounterId" element={<NurseAppointmentItemPage />} />
                                    <Route path="procedure/:encounterOrEventId" element={<CallCenterManagerProcedurePage />} />
                                    <Route path="appointment" element={<DoctorAppointmentPage />} />
                                    <Route path="account-edit" element={<AccountPage edit={true} />} />
                                    <Route path="profile" element={<ProfilePage />} />
                                    <Route path="account" element={<AccountInfoPage />} />
                                </>
                            ) : authUser?.role === UserRole.Practitioner ? (
                                <>
                                    {/* Practitioner's pages */}
                                    <Route index path="/" element={<Navigate to="appointments" replace />}></Route>
                                    <Route path="appointments/:appointmentId" element={<PractitionerAppointmentItemPage />} />

                                    {/* todo just for now we have the same page as for nurse */}
                                    <Route path="appointment" element={<DoctorAppointmentPage />} />
                                    <Route path="account-edit" element={<AccountPage edit={true} />} />
                                    <Route path="patients" element={<PractitionerPatientsPage />} />
                                    <Route path="profile" element={<ProfilePage />} />
                                    <Route path="account" element={<AccountInfoPage />} />
                                </>
                            ) : authUser?.role === UserRole.Head_physician ? (
                                <>
                                    {/* Head physician's pages */}
                                    <Route index path="/" element={<Navigate to="my-appointments" replace />}></Route>
                                    <Route path="my-appointments" element={<DoctorAppointmentsPage />} />
                                    <Route path="appointments" element={<HeadPhysicianAppointmentsPage />} />
                                    <Route
                                        path="appointments/:appointmentId"
                                        element={<HeadPhysicianAppointmentItemPage isProcedureCourse={false} />}
                                    />
                                    <Route
                                        path="procedure/patient/:appointmentId"
                                        element={<HeadPhysicianAppointmentItemPage isProcedureCourse={true} />}
                                    />
                                    <Route path="procedure/:encounterOrEventId" element={<HeadPhysicianProcedurePage />} />
                                    <Route path="patients" element={<HeadPhysicianPatientsPage />} />
                                    <Route path="staff" element={<HeadPhysicianStaffPage />} />
                                    <Route path="staff/:userId" element={<HeadPhysicianEmployeePage />} />
                                    <Route path="schedule" element={<ClinicAdminSchedule readonly={true} />} />
                                    <Route path="profile" element={<ProfilePage />} />
                                    <Route path="account" element={<AccountInfoPage />} />
                                    {/* todo just for now we have the same page as for nurse */}
                                    <Route path="appointment" element={<DoctorAppointmentPage />} />

                                    <Route path="account-edit" element={<AccountPage edit={true} />} />
                                </>
                            ) : authUser?.role === UserRole.Clinic_administrator ? (
                                <>
                                    {/* Clinic admin's pages */}
                                    <Route index path="/" element={<Navigate to="staff" replace />}></Route>
                                    <Route path="settings" element={<ClinicAdminSettingsPage />} />
                                    <Route path="staff" element={<ClinicAdminStaffPage />} />
                                    <Route path="staff/:userId" element={<ClinicAdminEmployeePage />} />
                                    <Route path="schedule" element={<ClinicAdminSchedule />} />
                                    <Route path="patients" element={<ClinicAdminPatientsPage />} />
                                    <Route path="patient/:userId" element={<ClinicAdminPatientPage />} />
                                    <Route path="agent-report" element={<ClinicAdminAgentReportPage />} />
                                </>
                            ) : authUser?.role === UserRole.Administrator ? (
                                <>
                                    {/* Clinic admin's pages */}
                                    <Route index path="/" element={<Navigate to="settings" replace />}></Route>
                                    <Route path="settings" element={<ClinicAdminSettingsPage />} />
                                </>
                            ) : authUser?.role === UserRole.Call_center_manager ? (
                                <>
                                    {/* Call center manager's pages */}
                                    <Route index path="/" element={<Navigate to="encounters" replace />}></Route>
                                    <Route path="encounters" element={<CallCenterManagerAppointmentsPage />}>
                                        <Route index path="" element={<Navigate to="all" replace />}></Route>
                                        <Route path="all" element={<CallCenterManagerAllAppointmentsPage />}></Route>
                                        <Route path="all/:encounterOrEventId" element={<CallCenterManagerAppointmentPage />} />
                                        <Route path="all/procedure/:encounterOrEventId" element={<CallCenterManagerProcedurePage />} />
                                        <Route path="events" element={<CallCenterManagerAppointmentEventsPage />}></Route>
                                        <Route path="events/:encounterOrEventId" element={<CallCenterManagerAppointmentPage forEvent={true} />} />
                                        <Route
                                            path="events/procedure/:encounterOrEventId"
                                            element={<CallCenterManagerProcedurePage forEvent={true} />}
                                        />
                                        <Route path="courses" element={<CallCenterManagerAllCoursesPage />}></Route>
                                    </Route>
                                    {/* todo shared route */}
                                    <Route path="staff/:userId" element={<ClinicAdminEmployeePage />} />
                                    <Route path="create-schedule" element={<CreateSchedulePage />} />
                                </>
                            ) : null}

                            {/* Patient medcard page */}
                            {authUser?.role === UserRole.Nurse ||
                            authUser?.role === UserRole.Practitioner ||
                            authUser?.role === UserRole.Call_center_manager ||
                            authUser?.role === UserRole.Head_physician ? (
                                <Route
                                    path="medcard/:patientId"
                                    element={<PatientMedcard canEdit={authUser?.role === UserRole.Head_physician} />}
                                ></Route>
                            ) : null}

                            {/* Appointments page */}
                            {authUser?.role === UserRole.Nurse || authUser?.role === UserRole.Practitioner ? (
                                <Route path="appointments" element={<DoctorAppointmentsPage />}></Route>
                            ) : null}
                        </Route>

                        {/* Anonymous pages */}
                        <Route element={<AnonymousLayout />}>
                            <Route index path="login" element={<LoginPage />} />
                            <Route index path="restore-password" element={<RegisterPage restore={true} />} />
                            <Route path="register" element={<RegisterPage />} />
                            <Route path="account" element={<AccountPage />} />
                            <Route path="change-password" element={<ChangePassword />} />
                            <Route path="confirm-email" element={<EmailConfirmationPage />} />
                            <Route path="confirm-email-restore" element={<EmailConfirmationPage restore={true} />} />
                            <Route path="confirm-mobile" element={<ConfirmMobilePage />} />
                            <Route path="account-success" element={<AccountSuccessPage />} />
                            <Route path="password-changed" element={<AccountSuccessPage restore={true} />} />
                        </Route>

                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </main>
            )}
        </>
    );
};
